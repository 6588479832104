import schttp from 'public/src/services/schttp/index'
import { asyncLoadFile } from '@shein/common-function'

// geetest的初始化因为语言上有一些差异，需要转换
function parseLanguage(lang) {
  if (lang === 'pt-br') {
    return 'pt'
  }
  if (lang === 'he'){
    return 'iw'
  }
  return lang
}

const biName = {
  'forgetPassword': 'click_fpwordriskverify',
  'forgetPhone': 'click_fpwordriskverify',
  'resetPhone': 'click_fpwordriskverify',
  'loginEmail': 'click_loginriskverify',
  'loginPhone': 'click_loginriskverify',
  'phoneCodeSend': 'click_loginriskverify',
  'signupEmail': 'click_registerriskverify',
  'signupPhone': 'click_registerriskverify',
  'quickRegister': 'click_registerriskverify'
}

const poskeys = ['JyRisk', 'JyRiskReg', 'JyRiskZTReg'] // 登录、注册、专题注册

class GeeTest {
    abtResult = {} // abt结果
    isSupportGeetest = true // 该站点是否支持极验
    isShouldInit = false // 是否应该初始化极验验证器，基于abt结果和极验服务状态
    info = {} // 注册结果信息
    challange = '' // 流水号
    verifyCb = [] // 验证结果回调
    gtInstance = null
    from = '' // 调用来源
    isReInitStatus = false
    isGtInstanceReady = false // 极验实例是否已经准备完成
    errorTriggerTimes = 0 // 错误触发次数
    reInitTimes = 0
    riskMaskValue = ''
    isResetting = false // 正在重置流水号
    isSetXhrListen = false // 是否已经设置过xhr事件监听，防止重复监听
    chanllangeTimestap = 0
    ajaxParams = ''
    staticUrl = ''
    fullpageJs = ''

    // 获取流水号
    register(cb, type = '') {
      schttp({ url: `/geetest/register?riskType=${type}`, timeout: 20000 })
        .then((res) => {
          const { status, data } = res
          this.fullpageJs = data?.fullpage || ''
          this.staticUrl = data?.static_servers?.[0] || ''
          this.info = data
          this.isShouldInit = status == 0
          if (this.isShouldInit) {
            this.chanllangeTimestap = Date.now()
            this.listenXhrComplete()
            this.fetchFullPageResource(cb, !!type)
            return
          }
          if (this.isReInitStatus) this.isReInitStatus = false
          typeof cb === 'function' && cb({ status: 'pass' })
        }) 
        .catch((err) => {
          this.errorReport(err, err.statusText, '/geetest/register')
          if (!this.isReInitStatus) typeof cb === 'function' && cb({ status: 'error' })
        })
    }
    // 拦截ajax.php作数据监听
    listenXhrComplete() {
      if (this.isSetXhrListen) return
      this.isSetXhrListen = true
      const orgOpen = XMLHttpRequest.prototype.open
      const self = this
      XMLHttpRequest.prototype.open = function () {
        let args = arguments
        this.addEventListener('load', function () {
          if (/geetest\/ajax\.php$/.test(this.responseURL)) {
            self.sendGtVerifyResult(this.responseText)
          }
          if (/geetest\/reset\.php$/.test(this.responseURL)) {
            self.chanllangeTimestap = Date.now()
            self.recallVerfy()
            self.isResetting = false
          }
        })
        this.addEventListener('error', function (err) {
          const path = args && args[1]
          if (path && /geetest\/(ajax|get|refresh|reset)\.php$/.test(path)) {
            self.errorReport(err, 'network error', path)
            if (!self.isGtInstanceReady) {
            // 实例初始化阶段出现错误，恢复按钮可点击性
              typeof self.initCb === 'function' && self.initCb()
            }
          }
        })
        this.addEventListener('timeout', function (err) {
          const path = args && args[1]
          if (path && /geetest\/(ajax|get|refresh|reset)\.php$/.test(path)) {
            self.errorReport(err, 'timeout', path)
            if (!self.isGtInstanceReady) {
            // 实例初始化阶段出现错误，恢复按钮可点击性
              typeof self.initCb === 'function' && self.initCb()
            }
          }
        })
        if (/geetest\/ajax\.php$/.test(args[1])) self.isSendingAjaxPhP = true
        orgOpen.apply(this, args)
      }

      const orgSend = XMLHttpRequest.prototype.send
      XMLHttpRequest.prototype.send = function () {
        if (self.isSendingAjaxPhP === true) {
          self.ajaxParams = arguments[0]
          self.isSendingAjaxPhP = false
        }
        orgSend.apply(this, arguments)
      }
    }
    // 数据上报
    sendGtVerifyResult(responseText) {
      let res = {} 
      // eslint-disable-next-line no-empty
      try{  res = JSON.parse(responseText) } catch(e) {}
      const data = res.data || {}
        
      this.riskMaskValue = data && data.risk

      let isSuccess = 1
      if (res.status === 0 && data.result === 'success' && data.sub_type === 'fullpage') {
        isSuccess = 0
      }
      const actName = biName[this.from]
      if (!this.from) return
      const params = typeof SaPageInfo !== 'undefined' ? SaPageInfo : { start_time: Date.now() }
      const actParams = {
        verification_result: isSuccess,
        sessionid_shein: res.token || '',
      }
      if (data.sub_type) actParams.sub_type = data.sub_type
      if (data.score) actParams.score = data.score
      if (data.result) actParams.success = data.result === 'success' ? 0 : 1
      const biData = Object.assign({}, params, {
        activity_name: actName,
        activity_param: actParams
      })
      sa('send', biData)
    }
    /**
     * 获取极验abt结果
     * @param {function} cb 结果回调函数
     */
    async getGtAbtResult(cb) {
    // 不支持的站点
      if (!this.isSupportGeetest) return typeof cb === 'function' && cb({ status: 'not_support' })
      this.initCb = cb
      try {
      // let abtInfo = await window._abt_server_provider.getUserAbtResult({ posKeys: poskeys.join(',') })
        let abtInfo = {
          JyRisk: {
            param: 'on',
            poskeys: 'JyRisk',
          },
          JyRiskReg: {
            param: 'on',
            poskeys: 'JyRiskReg',
          },
          JyRiskZTReg: {
            param: 'on',
            poskeys: 'JyRiskZTReg',
          }
        }
        this.setAbtResult(abtInfo)
        if (this.showInitGTinstance()) {
          this.register(cb)
          return
        }
      } catch (e) {
        this.abtResult = {}
      }
      this.isShoudInitGt = false
      typeof cb === 'function' && cb({ status: 'error' })
    }
    setAbtResult (res) {
      poskeys.forEach((k) => {
        this.abtResult[k] = res[k] || {}
      })
    }
  
    getItemResult (key) {
      return !(this.abtResult[key] && this.abtResult[key].param === 'off') && this.isShouldInit
    }
  
    showInitGTinstance () {
      return true
    // const res = this.abtResult
    // return poskeys.some((k) => res[k] && res[k].param === 'on')
    }

    urlConversion(path) {
      let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
      path = path.replace(reg, 'https://$2$3$4$5$6')
      return path
    }

    fetchFullPageResource(cb) {
      if (!this.isShouldInit || !this.isSupportGeetest) return
      if (this.isGtInstanceReady == true) {
        this.initGeetestInstance(cb)
        return 
      }
      this.staticUrl = this.urlConversion(this.staticUrl)
      const fullpageUrl = `${this.staticUrl}/${this.fullpageJs}`
      asyncLoadFile({
        label: 'script',
        attrs: {
          async: true,
          src: fullpageUrl,
        },
      })
        .then(() => {
          this.initGeetestInstance(cb)
        })
        .catch((err) => {
          console.log('fullpage.js error ......')
          console.log(err)
          this.errorReport(err, 'fullpage.js load error', '')
          typeof cb === 'function' && cb({ status: 'error' })
        })
    }

    initGeetestInstance (cb) {
      const options = { ...this.info, ...{
        product: 'bind', 
        lang: parseLanguage(gbCommonInfo.appLanguage),
        next_width: '300px',
        timeout: 20000,
        pure: true,
      } }
      try {
        const captchaObj = new window.Geetest(options)
        captchaObj.onReady(() => {
          this.isGtInstanceReady = true
          this.gtInstance = captchaObj
          typeof cb === 'function' && cb({ status: 'complete' })
          // 如果验证回调里面有队列，说明在初始化完成之前已经调用了验证接口，需要手动调用验证接口触发
          if (this.verifyCb.length > 0) {
            this.gtInstance.verify()
          }
        })
        captchaObj.onSuccess(() => {
          const { geetest_challenge } = captchaObj.getValidate()
          setTimeout(() => {
            this.verifyCb.forEach((callback) => {
              callback({ 
                status: 'success', 
                challenge: geetest_challenge, 
                risk: this.riskMaskValue 
              })
              this.verifyCb.shift()
            })
          }, 0)
        })
        captchaObj.onClose(() => {
          this.verifyCb.forEach((callback) => {
            callback({ status: 'close' })
            this.verifyCb.shift()
          })
        })
        captchaObj.onError((info) => {
          console.log('error sdk ....', info)
          this.errorReport(info, 'sdk error', '')
          typeof cb === 'function' && cb({ status: 'error' })
          this.verifyCb.forEach((callback) => {
            callback({ status: 'error' })
            this.verifyCb.shift()
          })
        })
      } catch(e) {
        this.errorReport(e.stack, 'sdk init error', '')
        typeof cb === 'function' && cb({ status: 'error' })
      }
    }
    /**
     * 极验验证结果回调
     * @param cb 验证结果回调方法
     */
    verify(cb, from = '') {
      console.log('reInitTimes', this.reInitTimes)
      if (!this.isGtInstanceReady || this.reInitTimes >= 4) { // 实例初始化阶段出现错误
        cb({ status: 'error' })
        return
      }
      this.from = from
      typeof cb === 'function' && this.verifyCb.push(cb)
      this.gtInstance?.verify()
    }

    verifyPromise (poskey, from = '') {
      return new Promise((resolve) => {
        if (!this.getItemResult(poskey)) return resolve({ status: 'abt-off' })
        this.verify((data) => {
          resolve(data)
        }, from)
      })
    }

    recallVerfy () {
    // 有可能重置流水号请求回来之前已经又点了一次验证, 需要重新调一次验证
      if (this.isResetting && this.verifyCb.length > 0) {
        this.gtInstance.verify()
      }
    }
    
    reset() {
      this.isResetting = true
      this.gtInstance.reset()
    }

    reInitGt(cb) {
      if (this.reInitTimes > 5) return
      this.reInitTimes++
      this.isReInitStatus = true
      this.getGtAbtResult(cb)
    }

    errorReport(err, type, path) {
      const errInfo = err && err.target ? err.target : err
      // 过滤1012日志
      if (typeof errInfo === 'object' && (errInfo.error_code == '1021' || errInfo.error_code == '1012')) return
      this.errorTriggerTimes++
      const { SA_REPORT_URL } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo.REPORT_URL || {} : {}
      // eslint-disable-next-line no-undef
      if (typeof ErrorJs != 'undefined') {
        // eslint-disable-next-line no-undef
        ErrorJs.sendError('error', SA_REPORT_URL + '/unusual', { 
          errorMark: 'GeeTestBrowserError', 
          errorType: type,
          errorInfo: JSON.stringify(errInfo),
          errorPath: path,
          errrorTimes: this.errorTriggerTimes,
        })
      }
    }
}

export default GeeTest
