// 定义ErrorJs 对象
// 主要用于兼容
export const definedErrorJs = (SILog) => {
  const { ERROR_JS_SWITCH_OLD_ON } = gbCommonInfo
  // 如果有配置了回滚开关，不执行后续代码
  const unReDefinedErrorJs = ERROR_JS_SWITCH_OLD_ON == 1
  if (!SILog || unReDefinedErrorJs) return

  // ErrorJs 的日志迁移
  const log_proxy = function(data) {
    const tag = data.tag || data.errorMark || data.type || data.errorType
    SILog.SILogger.logInfo({
      tag,
      message: tag,
      params: data,
    })
  }
  const monitor_proxy = function(data) {
    const { metricName, ...params } = data
    SILog.SIMetric.metricCount({
      metric_name: metricName,
      tags: params,
    })
  }
  // 加载顺序兼容
  if (window.ErrorJs) {
    // 1. 如果已经有定义（在全局 public/src/inline/header/error_report.js ）
    // 代理队列消费
    if (window.ErrorJs?.log_proxy?.queues?.length) {
      while (window.ErrorJs.log_proxy.queues.length) {
        const data = window.ErrorJs.log_proxy.queues.shift()
        log_proxy(data)
      }
    }
    // 代理队列消费
    if (window.ErrorJs?.monitor_proxy?.queues?.length) {
      while (window.ErrorJs.monitor_proxy.queues.length) {
        const data = window.ErrorJs.monitor_proxy.queues.shift()
        monitor_proxy(data)
      }
    }
    // 代理函数替换
    window.ErrorJs.log_proxy = log_proxy
    window.ErrorJs.monitor_proxy = monitor_proxy
  } else {
    // 2. 如果还没定义 （public/src/inline/header/error_report.js 还没加载）
    // 定义，防止业务代码报错
    window.ErrorJs = {
      sendError: function(errorType, url, data) {
        if (url.indexOf('/unusual') > -1) {
          // 1. 日志类型 -> monitor-core 上报
          // 2. 监控类型 -> sa 上报 -> 分流 monitor-core 上报到sls（用于观察还有多少指标没有迁移）
          if (data.reportList) {
            data.reportList.forEach(function(item) {
              if (item.metricName) {
                // 对应的监控指标上报
                monitor_proxy(item)
              }
            })
          }
          // 上报到 monitor-core 日志
          log_proxy(data)
          return
        }
        // 旧逻辑上报到sa
        if (window.sa) {
          window.sa(errorType, url, data)
          // 上报到 monitor-core 日志
          log_proxy(
            Object.assign(data, {
              tag: 'error-js-report-other',  // 用于后续筛选是否有遗漏日志未迁移
            })
          )
        }
      }
    }
  }
}
