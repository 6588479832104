/**
 * 需要收集的接口参数字段
 */

export const config = {
  '/api/cart/checkcart/get': { all: true },
  '/api/cart/add/create': { all: true },
  '/api/cart/add_mall/create': { all: true },
  '/api/cart/update': { all: true },
  '/api/cart/update_attr_mall/update': { all: true },
  '/api/cart/delete': { all: true },
  '/api/cart/batchDelete/update': { all: true },
  '/cart/saveforlater': { all: true },
  '/api/user/wishlist/add': { all: true },
  '/api/cart/batchWishGoods/update': { all: true },
  '/api/checkout/paymentList/get': { all: false, excludes: ['card_no', 'card_pin'] },
  '/api/checkout/shippingList/get': { all: false, excludes: ['card_no', 'card_pin'] },
  '/checkout/cacu_total': { all: true },
  '/api/checkout/mallCacuTotal/get': { all: true },
  '/api/checkout/order/update': { all: false, includes: ['editType'] },
  '/checkout/switch_payment': { all: true },
  '/ltspc/pay/unPay/unifiedPay': { all: false, includes: ['billno', 'paymentCode', 'routeId'] },
  '/ltspc/pay/binCheck': { all: true },
  '/ltspc/pay/preRouting': { all: true },
  'ltspc/pay/unPay/complete': { all: true },
}

// 特殊正则类型
export const regExpConfig = [
  { path: /^\/user\/orders\/detail\/\w+/, all: true },
]
