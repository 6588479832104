/**
 * 
 * @desc 个性化开关utils逻辑前端用
 * 
 * */ 
import { getCookie } from '@shein/common-function'

export const TRACK_KEY = 'uberctx-personal-switch'
const COOKIE_KEY = 'uswitch'
const cookieMapArray = [
  ['s', 'search'], 
  ['r', 'recommend'], 
  ['u', 'userGrowth']
]

export const getSwitchByCookie = () => {
  // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
  const cookieValue = getCookie(COOKIE_KEY)
  let result
  if (cookieValue) {
    // s-1.r-0.u-1 => [[s, 1], [r, 0], [u, 1]]
    // [[r, 1],r, 0]]
    const cookieArray = decodeURIComponent(cookieValue).split('.').map(_ => _.split('-'))
    result = cookieMapArray.reduce((acc, cur) => {
      const curClose = cookieArray.find(([key, value]) => key === cur[0] && value == 0)
      acc[cur[1]] = curClose ? 0 : 1
      return acc
    }, {})
  }
  return result
}


export const setPersonalizedHeader = (config) => {
  const commonHeaders = {
  }
  const result = getSwitchByCookie()
  if (result && Object.keys(result).length) { commonHeaders[TRACK_KEY] = encodeURIComponent(JSON.stringify(result)) }
  config.headers = Object.assign(commonHeaders, config.headers)
  // END
  return config
}
